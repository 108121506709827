import "./App.css";
import { FaTwitter, FaMediumM, FaTelegramPlane } from "react-icons/fa";
import { BsDiscord } from "react-icons/bs";

function App() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="App">
      <header className="App-header">
        <h3
          style={{
            width: 300,
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <div
            className="expand"
            onClick={() => openInNewTab("https://twitter.com/downfall")}
          >
            <FaTwitter />
          </div>
          {/* <div
            className="expand"
            onClick={() => openInNewTab("https://discord.com")}
          >
            <BsDiscord />
          </div>
          <div
            className="expand"
            onClick={() => openInNewTab("https://medium.com")}
          >
            <FaMediumM />
          </div>
          <div
            className="expand"
            onClick={() => openInNewTab("https://telegram.com")}
          >
            <FaTelegramPlane />
          </div> */}
        </h3>
      </header>
    </div>
  );
}

export default App;
